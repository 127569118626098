import { createContact } from "@/utils/contacts/index";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { ls } from "@/utils/helpers/localstorage";
import { useToast } from "@/utils/helpers/toastManager";
import { useBlock } from "@/utils/hooks/useBlock";
import { useChatbotInfo } from "@/utils/hooks/useChatbotInfo";
import { useContactPhoneNumbersWithTypesAndCaps } from "@/utils/hooks/useContactPhoneNumbers";
import { useContacts } from "@/utils/hooks/useContacts";
import { useDeleteConversationModal } from "@/utils/hooks/useDeleteConversationModal";
import Conversation from "@/utils/messaging/conversation/Conversation";
import {
  setSelectedConversationId,
  useSelectedConversation,
} from "@/utils/messaging/conversation/ConversationState";
import { css } from "@emotion/react";
import { CSSObject } from "@emotion/styled";
import BlockIcon from "@mui/icons-material/Block";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MessageIcon from "@mui/icons-material/Message";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Box } from "../../pages/layout/Layout.style";
import { colors } from "../../styles/global.styles";
import {
  contactState,
  Phone,
  resetContactState,
} from "../../utils/contacts/contactState";
import { generateRandomString, insertOdd } from "../../utils/helpers/Utils";
import WebGwContact from "../../utils/helpers/WebGwContact";
import ContactActionsHeader from "../contacts/ContactActionsHeader";
import { avatar } from "../contacts/ContactInfo.style";
import { DropDownButton, DropDownContainer } from "../keyPad.style";
import { Avatar, AvatarBackground, avatarSize } from "../shared/Avatar";
import { IconButtonBox } from "../shared/Button";
import ContactInfoDropdown from "../shared/ContactInfoDropdown";
import ContactModal from "../shared/ContactModal";
import {
  dropdownContainer,
  footerDropdownItem,
  headerDropdown,
} from "../shared/Dropdown.style";
import { ThreeLinesLoaderBox } from "../shared/Loaders/ThreeLines";
import SectionHeader from "../shared/SectionHeader";

type ChatInfoProps = {
  conversation: Conversation;
  closeChatInfo?: (e?: React.MouseEvent) => void;
  contact?: WebGwContact;
  setShowCreateGroupChat?: (show: boolean) => void;
  chatInfoOpen?: boolean;
  contactBlocked?: boolean;
  onContactBlocked?: (blocked: boolean) => void;
} & (
  | {
      isChatbot?: never;
      openContactInfo?: never;
      overlayMessages?: never;
    }
  | {
      isChatbot: boolean;
      openContactInfo?: (e?: React.MouseEvent) => void;
      overlayMessages?: () => void;
    }
);

export default function ChatInfo({
  ref,
  conversation,
  closeChatInfo,
  isChatbot,
  openContactInfo,
  contact: _contact,
  overlayMessages,
  setShowCreateGroupChat,
  chatInfoOpen,
  contactBlocked,
  onContactBlocked,
  ...props
}: ChatInfoProps &
  React.ComponentProps<"div"> & {
    ref?: React.RefObject<HTMLDivElement | null>;
  }) {
  const isGroupChat = conversation.getIsGroupChat();
  const currentContactId = useRef<string | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetCounter, setResetCounter] = useState(0);
  const { showToast } = useToast();

  const selectedConversation = useSelectedConversation({
    enabled: !_contact,
  });
  // TODO group chat
  const selectedConversationFirstContact =
    selectedConversation?.participants[0] ?? _contact;
  const contacts = useContacts();
  const contact =
    (selectedConversationFirstContact &&
      contacts?.find((c) => c.id === selectedConversationFirstContact.id)) ??
    selectedConversationFirstContact;

  useLayoutEffect(() => {
    currentContactId.current = contact?.id;
  });

  const [dropdownOpened, setOpenDropdown] = useState(false);

  const closeDropdown = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setOpenDropdown(false);
  };

  const toggleDropdown = () => {
    setOpenDropdown(!dropdownOpened);
  };

  const showModal = () => {
    resetContactState();
    setResetCounter((prev) => prev + 1);
    setIsModalOpen(true);
    const number: Phone = {
      number: contact?.getMainPhoneNumber() || "",
      type: "",
      id: generateRandomString(6),
      caps: false,
    };
    contactState.phoneNumber = [number];
  };

  const handleOk = () => {
    setIsModalOpen(false);
    void createContact(contactState, showToast);
    resetContactState();
  };

  const handleCancel = () => {
    resetContactState();
    setIsModalOpen(false);
  };

  const { data: phoneNumbers } = useContactPhoneNumbersWithTypesAndCaps(
    contact?.id
  );

  const dropdownRef = useRef<HTMLDivElement>(null!);

  useOnClickOutside(
    dropdownRef,
    () => {
      closeDropdown();
    },
    "mouseup"
  );

  //TODO DON'T USE ODIENCE IN VERSE NEED TO REPLACE
  const localOdienceUser = useMemo(
    () => JSON.parse(ls.getOdienceUser() || "{}"),
    []
  );

  const createGroupChat = () => {
    closeChatInfo?.();
    setShowCreateGroupChat?.(true);
  };

  const block = useBlock({
    contact,
    forceBlock: contactBlocked,
    onContactBlocked,
    refreshDependencies: [chatInfoOpen],
  });

  const chatName =
    (isGroupChat
      ? conversation.getName(true)
      : contact?.noNameReturnPhoneNumber(undefined, true)) || "";

  const { modal: deleteConversationModal, show: showDeleteConversationModal } =
    useDeleteConversationModal(conversation, chatName, closeChatInfo);

  if (!contact) {
    console.warn("No contact for ChatInfo");
    return null;
  }

  const handleSelectConversation = (phoneNumber: string | null) => {
    if (!phoneNumber) return;

    const conversationId = Conversation.getOrCreate({
      phoneNumber,
      contactToLinkIfCreate: contact,
    }).conversation.id;
    setSelectedConversationId(conversationId);

    closeChatInfo?.();
    overlayMessages?.();
  };

  return (
    <Box
      ref={ref}
      css={{
        height: "100%",
        width: "100%",
        zIndex: "2",
        color: colors.primaryTextColor,
      }}
      {...props}
    >
      <ContactModal
        open={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        key={resetCounter}
      />

      {/* TODO clean this up */}
      <SectionHeader
        pageName={
          isChatbot
            ? "Chatbot Info"
            : (isGroupChat ? "Group " : "") + "Chat Info"
        }
        iconButton={closeChatInfo && <CloseRoundedIcon />}
        onClick={closeChatInfo}
        css={{
          color: colors.primaryTextColor,
        }}
      >
        {!isChatbot && (
          <div
            css={{
              position: "relative",
              display: "flex",
              flexGrow: "1",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {!isGroupChat && (
              <IconButtonBox
                css={{
                  backgroundColor: dropdownOpened
                    ? colors.secondaryBackground
                    : "transparent",
                }}
                ref={dropdownRef}
                onClick={toggleDropdown}
              >
                <MoreVertIcon />
                {dropdownOpened && (
                  <DropDownContainer css={[dropdownContainer, headerDropdown]}>
                    {openContactInfo && (
                      <DropDownButton
                        css={footerDropdownItem}
                        onClick={() => openContactInfo()}
                      >
                        <PersonIcon />
                        View Contact
                      </DropDownButton>
                    )}
                    {!openContactInfo && (
                      <DropDownButton
                        css={footerDropdownItem}
                        onClick={() => showModal()}
                      >
                        <PersonAddOutlinedIcon />
                        Add Contact
                      </DropDownButton>
                    )}
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={() => block.show()}
                    >
                      <BlockIcon /> {block.value}
                    </DropDownButton>
                  </DropDownContainer>
                )}
              </IconButtonBox>
            )}
          </div>
        )}
      </SectionHeader>

      <div css={{ padding: "2em 0 1em" }}>
        <AvatarBackground css={avatar}>
          <Avatar
            contact={contact}
            isGroupChat={conversation.getIsGroupChat()}
            photo={conversation.getIconUrl()}
          />
        </AvatarBackground>
      </div>

      <span css={{ fontSize: "1.5em", textAlign: "center" }}>{chatName}</span>

      {!isGroupChat && (
        <div css={{ padding: "1.5em 0", width: "100%" }}>
          <section
            css={{
              color: colors.primaryAccentColor,
              background: colors.secondaryBackground,
              width: "100%",
              minHeight: "4em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1.5em",
              lineHeight: "1",
            }}
          >
            {!isChatbot ? (
              <ContactActionsHeader
                mainPhoneNumber={contact.getMainPhoneNumber()}
                // TODO: should this be restricted to rcs numbers? right now, phoneNumbers is unfiltered.
                rcsPhoneNumbers={phoneNumbers}
                onSelectConversation={handleSelectConversation}
                contactBlocked={contactBlocked}
                onContactBlocked={onContactBlocked}
              />
            ) : (
              <>
                <ContactInfoDropdown
                  options={contact.getAllPhoneNumbersWithTypes()}
                  onSelect={([phoneNumber, _type]) => {
                    handleSelectConversation(phoneNumber);
                  }}
                  placeholder={<MessageIcon />}
                  icon={<MessageOutlinedIcon />}
                />
              </>
            )}
          </section>
        </div>
      )}

      <Box
        style={{
          width: "95%",
          overflowY: "auto",
          marginRight: "0.5em",
        }}
      >
        {!isChatbot ? (
          <>
            {!conversation.getIsGroupChat() && !contact.isChatbot && (
              <section
                css={sectionCss.main}
                style={{
                  marginBottom: "2em",
                  color: colors.primaryAccentColor,
                }}
              >
                {/* TODO - Need to decide if we want to show this or not when there are more than number per contact and what the UI will be */}
                {/* <span css={[sectionCss.title, sectionCss.spacedTitle]}>
                  CONTACT
                </span> */}

                <ChatInfoButtonListItem
                  icon={
                    <GroupsOutlinedIcon
                      css={{
                        fontSize: "1.5em",
                        color: colors.primaryAccentColor,
                      }}
                    />
                  }
                  onClick={createGroupChat}
                >
                  Create group chat with{" "}
                  {contact.noNameReturnPhoneNumber(
                    contact.getMainPhoneNumber()
                  )}
                </ChatInfoButtonListItem>

                <hr
                  css={[sectionCss.title, sectionCss.spacedTitle]}
                  style={{ color: colors.secondaryBackground }}
                />
              </section>
            )}
            <section
              css={sectionCss.main}
              style={{ ...(isGroupChat && { marginTop: "2em" }) }}
            >
              <span css={[sectionCss.title, sectionCss.spacedTitle]}>
                CONVERSATION
              </span>
              <ChatInfoButtonListItem
                disabled
                isGroupChat={isGroupChat}
                icon={<VolumeOffOutlinedIcon css={{ fontSize: "1.5em" }} />}
              >
                Mute conversation
              </ChatInfoButtonListItem>
              <ChatInfoButtonListItem
                disabled // TODO
                isGroupChat={isGroupChat}
                icon={
                  <BookmarkBorderOutlinedIcon css={{ fontSize: "1.5em" }} />
                }
              >
                View bookmarks
              </ChatInfoButtonListItem>
              <ChatInfoButtonListItem
                disabled // TODO
                isGroupChat={isGroupChat}
                icon={<BrushOutlinedIcon css={{ fontSize: "1.5em" }} />}
              >
                Customize your theme
              </ChatInfoButtonListItem>
              {!isGroupChat && (
                <ChatInfoButtonListItem
                  icon={<DeleteIcon css={{ fontSize: "1.5em" }} />}
                  onClick={showDeleteConversationModal}
                >
                  Delete Conversation
                </ChatInfoButtonListItem>
              )}
            </section>
          </>
        ) : (
          <ChatbotSection contact={contact} />
        )}

        {isGroupChat && (
          <>
            <Box
              style={{
                marginTop: "2em",
                width: "90%",
                backgroundColor: colors.secondaryBackground,
                flexDirection: "column",
                justifyContent: "left",
                color: colors.primaryTextColor,
                flexGrow: "0",
                flexShrink: "0",
              }}
            >
              <section css={sectionCss.main}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <span css={[sectionCss.title]}>
                    PARTICIPANTS ({conversation.participants.length + 1})
                  </span>
                  <button disabled style={{ color: colors.tertiaryTextColor }}>
                    <PersonAddIcon />
                  </button>
                </div>

                <ParticipantLine
                  key={"myself"}
                  mySelf={localOdienceUser}
                  isMyselfAdmin={conversation.getIsLocalUserAdmin()}
                />

                {conversation.participants.map((contact) => {
                  return (
                    <ParticipantLine
                      key={contact.id}
                      contact={contact}
                      contactAdminId={conversation.getContactAdminId()}
                    />
                  );
                })}
              </section>
            </Box>

            <section css={sectionCss.main} style={{ marginTop: "2em" }}>
              <ChatInfoButtonListItem
                disabled
                isGroupChat={isGroupChat}
                icon={<LogoutIcon css={{ fontSize: "1.5em" }} />}
              >
                Leave conversation
              </ChatInfoButtonListItem>
            </section>
          </>
        )}
      </Box>
      {block.modal}
      {deleteConversationModal}
    </Box>
  );
}

const avatarCss = {
  minWidth: avatarSize,
  minHeight: avatarSize,
  width: avatarSize,
  height: avatarSize,
} as const satisfies CSSObject;

function ParticipantLine({
  mySelf,
  isMyselfAdmin,
  contact,
  contactAdminId,
}: {
  mySelf?: { avatar: string; name: string; msisdn: string };
  isMyselfAdmin?: boolean;
  contact?: WebGwContact;
  contactAdminId?: string;
}) {
  return (
    <div
      style={{
        marginLeft: "1em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Avatar
          style={avatarCss}
          contact={mySelf ? undefined : contact}
          photo={mySelf?.avatar}
        />
      </div>
      <div
        style={{
          color: colors.primaryTextColor,
          display: "flex",
          width: "100%",
          alignItems: "center",
          paddingLeft: "1em",
        }}
      >
        {mySelf
          ? mySelf.name || formatPhoneNumber(mySelf.msisdn, "E123")
          : contact?.noNameReturnPhoneNumber()}

        {mySelf && <>&nbsp;(You)</>}

        {((contact && contact?.id === contactAdminId) || isMyselfAdmin) && (
          <span style={{ color: colors.primaryAccentColor }}>
            &nbsp;(Admin)
          </span>
        )}
      </div>
      <div>
        {mySelf && (
          <button style={{ color: colors.tertiaryTextColor }}>
            <EditIcon />
          </button>
        )}
      </div>
    </div>
  );
}

function ChatbotSection({ contact }: { contact: WebGwContact }) {
  const { data, isLoading } = useChatbotInfo(contact);

  if (isLoading) {
    return <ThreeLinesLoaderBox />;
  }

  if (!data) {
    return (
      <p css={{ color: colors.secondaryTextColor, margin: "1em 0" }}>
        This bot may not be available at the moment. You may not receive
        responses.
      </p>
    );
  }

  const { description, addressEntries, website } = data;

  let hostname: string | undefined;
  if (website) {
    try {
      hostname = new URL(website).hostname;
    } catch {
      console.warn("unable to parse url", website);
    }
  }

  return (
    <section css={sectionCss.main}>
      {insertOdd(
        [
          description && (
            <div key="description">
              <span css={sectionCss.title}>Description</span>
              <span css={[sectionCss.text, { padding: "0.5em 0" }]}>
                {description}
              </span>
            </div>
          ),
          addressEntries && addressEntries.length > 0 && (
            <div key="addressEntries">
              <span css={sectionCss.title}>
                Address{addressEntries.length > 1 ? "es" : ""}
              </span>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.25em",
                  padding: "0.5em 0",
                }}
              >
                {addressEntries.map(
                  ({ "addr-string": address, label }, idx) => (
                    <div key={idx} css={sectionCss.text}>
                      <span>{address}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          ),
          website && (
            <div key="website">
              <span css={sectionCss.title}>Website</span>
              <a
                css={[sectionCss.text, { padding: "0.5em 0" }]}
                href={website}
                target="_blank"
                rel="noreferrer"
              >
                {hostname}
              </a>
            </div>
          ),
        ].filter(Boolean),
        (idx) => (
          <hr key={idx} css={[sectionCss.title, { height: "1px" }]} />
        )
      )}
    </section>
  );
}

const sectionCss = {
  main: css({
    display: "flex",
    flexDirection: "column",
    gap: "1.25em",
    color: colors.primaryTextColor,
    width: "100%",
    padding: "0 2em",
    fontSize: "0.9em",
    fontWeight: "bold",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      gap: "0.25em",
    },
  }),
  text: css({
    color: colors.primaryTextColor,
  }),
  darkText: css({
    color: colors.secondaryTextColor,
  }),
  title: css({
    color: colors.secondaryTextColor,
    textTransform: "uppercase",
    fontSize: "0.9em",
    fontWeight: "normal !important",
  }),
  spacedTitle: css({
    marginLeft: "1em",
  }),
};

type ChatInfoButtonListItemProps = {
  icon: React.ReactElement;
  isGroupChat?: boolean;
  children: React.ReactNode;
} & React.ComponentProps<"button">;
const ChatInfoButtonListItem = ({
  ref,
  icon,
  isGroupChat,
  children,
  ...props
}: ChatInfoButtonListItemProps & {
  ref?: React.RefObject<HTMLButtonElement>;
}) => {
  return (
    <button
      ref={ref}
      css={{
        display: "flex",
        alignItems: "center",
        gap: "1em",
        height: "3em",
        width: "auto",
        borderRadius: "12px",
        padding: isGroupChat ? "0 2em" : "0 1em",
        ":hover": {
          background: colors.fourthBackground,
        },
        ":disabled": {
          color: colors.tertiaryTextColor,
        },
      }}
      {...props}
    >
      {icon}
      <span>{children}</span>
    </button>
  );
};
