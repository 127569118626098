import { colors } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import WebGwContact, { WebGwContactList } from "@/utils/helpers/WebGwContact";
import { useContacts } from "@/utils/hooks/useContacts";
import { FilePreviewRes } from "@/utils/hooks/useFilePreview";
import { useAtomValue } from "jotai";
import { useRef } from "react";
import ContactsList, {
  ContactSelectHandler,
} from "../../components/contactListScreen/ContactsList";
import SvgGraphic from "../../components/shared/SvgGraphic";
import { Box } from "../layout/Layout.style";
import CreateGroupChat, { CreateGroupChatRef } from "./CreateGroupChat";
import ListExistingGroupChats from "./ListExistingGroupChats";
import NewConversationScreenHeader from "./NewConversationScreenHeader";

export default function NewConversationScreen({
  contacts,
  onRemoveContact,
  onConversationStarted,
  contactSelectHandler,
  onShowContactPicker,
  onAddNumber,
  showLoadingPhoneIcon,
  searchContactQuery,
  setSearchContactQuery,
  showStartGroupConversation,
  onShowStartGroupConversation,
  newConversation,
  setShowCreateGroupChat,
  addContactInputDefaultValue,
  filePreviewRes,
}: {
  contacts: WebGwContact[];
  onRemoveContact: (
    contactId: string,
    currentAddContactInputValue?: string
  ) => void;
  onConversationStarted?: (conversationId) => void;
  contactSelectHandler: ContactSelectHandler;
  onShowContactPicker: (show: boolean) => void;
  onAddNumber?: (number: string) => void;
  showLoadingPhoneIcon?: boolean;
  searchContactQuery: string;
  setSearchContactQuery: (s: string) => void;
  showStartGroupConversation: boolean;
  onShowStartGroupConversation: (show: boolean) => void;
  newConversation?: boolean;
  setShowCreateGroupChat?: (show: boolean) => void;
  addContactInputDefaultValue?: string;
  filePreviewRes: FilePreviewRes;
}) {
  const createGroupChatRef = useRef<CreateGroupChatRef>(null);

  const savedContacts: WebGwContactList | null | undefined = useContacts();
  const hasContacts = (savedContacts?.length ?? 0) > 0;
  const isGroupChat = contacts.length > 1;

  const handleStartGroupConversation = () => {
    onShowStartGroupConversation(true);
  };

  const handleBackToList = () => {
    onShowStartGroupConversation(false);
  };

  const groupChatBoxWidth = showStartGroupConversation ? "95%" : "80%";

  const handleGetGroupChatSubject = () => {
    return createGroupChatRef?.current?.getSubject();
  };

  const handleGetGroupChatIconUrl = () => {
    return createGroupChatRef?.current?.getIconUrl();
  };

  const conversationUpgradingToGroupChat = !!useAtomValue(
    atoms.messaging.conversationUpgradingToGroupChat
  );

  return (
    <Box
      style={{
        paddingBottom: "0",
        paddingTop: "0",
        backgroundColor: newConversation
          ? colors.secondaryBackground
          : colors.primaryBackground,
        transition: "background-color 0.25s ease",
      }}
    >
      <NewConversationScreenHeader
        onRemoveContact={onRemoveContact}
        setSearchQuery={setSearchContactQuery}
        contacts={contacts}
        readOnly={showStartGroupConversation}
        onShowContactPicker={onShowContactPicker}
        onAddNumber={onAddNumber}
        showLoadingPhoneIcon={showLoadingPhoneIcon}
        closeCreateGroupChat={
          conversationUpgradingToGroupChat && setShowCreateGroupChat
            ? () => setShowCreateGroupChat(false)
            : undefined
        }
        defaultValue={addContactInputDefaultValue}
      />
      {searchContactQuery && hasContacts ? (
        <ContactsList
          searchQuery={searchContactQuery}
          contactSelectedHandler={contactSelectHandler}
          ignoreContacts={contacts}
        />
      ) : isGroupChat ? (
        <div
          style={{
            width: groupChatBoxWidth,
            marginTop: "3em",
            display: "flex",
            flexDirection: "column",
            flex: "1",
          }}
        >
          {!showStartGroupConversation ? (
            <ListExistingGroupChats
              onStartGroupConversation={handleStartGroupConversation}
            />
          ) : (
            <CreateGroupChat
              ref={createGroupChatRef}
              onBackToList={handleBackToList}
              onGetGroupChatSubject={handleGetGroupChatSubject}
              onGetGroupChatIconUrl={handleGetGroupChatIconUrl}
              onConversationStarted={onConversationStarted}
              contacts={contacts}
              handleAddFiles={filePreviewRes.handleAddFiles}
              filePreviewRes={filePreviewRes}
            />
          )}
        </div>
      ) : (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            paddingTop: "5vh",
          }}
        >
          <p
            css={{
              color: colors.primaryTextColor,
              textAlign: "center",
              padding: "0 1.5em",
            }}
          >
            Enter a Contact Name or Number above to start a new conversation
          </p>
          <SvgGraphic src="/assets/Chat_Graphic.svg" />
        </div>
      )}
    </Box>
  );
}
