import ChatFooter, { ChatFooterRef } from "@/components/chatScreen/ChatFooter";
import FileTransferPreview from "@/components/chatScreen/FileTransferPreview";
import { DefaultGroupChatAvatar } from "@/components/shared/Avatar";
import { ThreeLinesLoaderBox } from "@/components/shared/Loaders/ThreeLines";
import { colors } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { upload } from "@/utils/helpers/fileUtils";
import { FilePreviewRes } from "@/utils/hooks/useFilePreview";
import { getMaxFileSizeAllowed } from "@/utils/messaging";
import {
  fileToBase64,
  formatFileSizeToHumanReadable,
} from "@/utils/messaging/conversation/conversationUtils";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { ChangeEvent, useImperativeHandle, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Box } from "../layout/Layout.style";

const noop = () => {};

const CreateGroupChat = ({
  ref,
  onBackToList,
  onGetGroupChatSubject,
  onGetGroupChatIconUrl,
  contacts,
  onConversationStarted,
  handleAddFiles,
  filePreviewRes,
}: {
  onBackToList: () => void;
  onGetGroupChatSubject: () => string | undefined;
  onGetGroupChatIconUrl: () => Promise<string | undefined> | undefined;
  contacts: WebGwContact[];
  onConversationStarted?: (conversationId) => void;
  handleAddFiles: (files: File[]) => void;
  filePreviewRes: FilePreviewRes;
} & {
  ref?: React.RefObject<CreateGroupChatRef | null>;
}) => {
  const chatFooterRef = useRef<ChatFooterRef>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const subjectInputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined
  );
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [creationInProgress, setCreationInProgress] = useState(false);

  useImperativeHandle(ref, () => ({
    getSubject: () => {
      return subjectInputRef.current?.value;
    },
    getIconUrl: async () => {
      return selectedFile ? await upload(selectedFile) : undefined;
    },
  }));

  const handleGroupChatCreationInProgress = () => {
    setCreationInProgress(true);
  };

  const handleIconPickerClick = () => {
    if (creationInProgress) {
      return;
    }

    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleIconChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const maxFileSize = await getMaxFileSizeAllowed();

      if (file.size > maxFileSize) {
        toast(
          `File size cannot be more than ${formatFileSizeToHumanReadable(
            maxFileSize
          )}`,
          {
            duration: 5000,
            position: "bottom-right",
            style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
          }
        );
        return;
      }

      setSelectedFile(file);
      setSelectedImage(await fileToBase64(file));
    }
  };

  return (
    <div
      style={{
        width: "100%",
        color: colors.primaryTextColor,
        display: "flex",
        flexDirection: "column",
        flex: "1",
        opacity: creationInProgress ? "0.5" : "1",
        position: "relative",
      }}
    >
      {creationInProgress && <ThreeLinesLoaderBox absolute={true} />}
      <div
        style={{
          width: "100%",
          padding: "1em",
          color: colors.primaryTextColor,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          onClick={creationInProgress ? noop : () => onBackToList()}
          style={{
            fontSize: "0.9em",
            cursor: creationInProgress ? "inherit" : "pointer",
          }}
        >
          &#60; Back to list
        </div>
        <Box
          style={{
            width: "auto",
            backgroundColor: colors.primaryBackgroundLighter,
            flexDirection: "column",
            justifyContent: "center",
            margin: "2em",
            padding: "1.5em",
            color: colors.primaryTextColor,
            alignItems: "start",
          }}
        >
          <div style={{ marginBottom: "1em" }}>Group Chat Info</div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "relative",
                cursor: creationInProgress ? "inherit" : "pointer",
              }}
              onClick={handleIconPickerClick}
            >
              {selectedImage ? (
                <div
                  style={{
                    width: "6em",
                    height: "6em",
                    backgroundImage: `url(${selectedImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <DefaultGroupChatAvatar style={{ width: "6em" }} />
              )}

              <div
                style={{
                  position: "absolute",
                  backgroundColor: colors.secondaryBackground,
                  padding: "5px",
                  paddingBottom: "2px",
                  borderRadius: "50%",
                  bottom: "0",
                  right: "0",
                }}
              >
                <PhotoCameraIcon />
              </div>
            </div>
            <div style={{ marginLeft: "2em", flex: "1" }}>
              <div
                style={{
                  marginBottom: "1em",
                  color: colors.secondaryTextColor,
                  letterSpacing: "1.8px",
                  fontWeight: "",
                }}
              >
                GROUP CHAT NAME
              </div>
              <input
                disabled={creationInProgress}
                ref={subjectInputRef}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: colors.secondaryBackground,
                  color: colors.primaryTextColor,
                }}
                css={{
                  width: "100%",
                  minHeight: "3em",
                  backgroundColor: colors.secondaryBackground,
                  borderRadius: "10px",
                  padding: "1em",
                  "::placeholder": {
                    color: colors.primaryTextColor + " !important",
                  },
                }}
                type="text"
                placeholder="Group Chat"
                onKeyDown={(e) => {
                  if (!creationInProgress) {
                    if (chatFooterRef.current) {
                      if (e.key === "Enter") {
                        chatFooterRef.current.sendMessage();
                      } else if (e.key === "Tab") {
                        chatFooterRef.current.focus();
                      }
                    }
                  }
                }}
                onClick={(e) => e.preventDefault()}
              />
            </div>
          </div>
        </Box>
      </div>
      <div
        style={{
          position: "relative",
          flex: 1,
        }}
      >
        <div
          css={{
            width: "100%",
            position: "absolute",
            bottom: "0",
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: colors.secondaryTextColor,
              marginBottom: "0.5em",
              fontSize: "0.9em",
            }}
          >
            Send a message to create the group chat
          </div>
          <FileTransferPreview
            filePreviewRes={filePreviewRes}
            chatFooterRef={chatFooterRef}
          />
          <ChatFooter
            contacts={contacts}
            onGroupChatCreationInProgress={handleGroupChatCreationInProgress}
            onConversationStarted={onConversationStarted}
            onGetGroupChatSubject={onGetGroupChatSubject}
            onGetGroupChatIconUrl={onGetGroupChatIconUrl}
            ref={chatFooterRef}
            onAddFiles={handleAddFiles}
            pendingFilesProxy={filePreviewRes.pendingFilesProxy}
          />
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleIconChange}
        style={{ display: "none" }}
        ref={imageInputRef}
      />
    </div>
  );
};

export type CreateGroupChatRef = {
  getSubject: () => string | undefined;
  getIconUrl: () => Promise<string | undefined>;
};

export default CreateGroupChat;
